import App from 'srs.sharedcomponents/lib/esm/App'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { ApplicationInsights, IPageViewTelemetry } from '@microsoft/applicationinsights-web'
import { env } from 'srs.sharedcomponents/lib/esm/api/env'
import { store } from 'srs.sharedcomponents/lib/esm/redux/store'
import { uuidv4 } from "srs.sharedcomponents/lib/esm/utils/helpers";
import axios, { AxiosResponse } from "axios";
import RouterProvider from "srs.sharedcomponents/lib/esm/components/context/router/RouterProvider";
import routerConfig from "srs.sharedcomponents/lib/esm/components/config/routes";

interface IAppWrapper {
  appInsights: ApplicationInsights
}

const AppWrapper = ({ appInsights }: IAppWrapper) => {
  const location = useLocation()

  useEffect(() => {
    const myRequestInterceptor = axios.interceptors.request.use((request) => {
      const { method, url, headers } = request;

      if (request.headers) {
        request.headers['X-Correlation-Id'] = uuidv4();
      }

      if (request) {
        appInsights.trackEvent({
          name: 'API Request Subaru CA',
          properties: {
            ApplicationName: "subaru-ca",
            RequestPath: url ?? '',
            Method: method ?? '',
            CorrelationId: (headers ? headers['X-Correlation-Id'] : ""),
          }
        });
      }

      return request;
    });

    return () => {
      axios.interceptors.request.eject(myRequestInterceptor)
    }
  })

  useEffect(() => {
    const myResponseInterceptor = axios.interceptors.response.use((response: AxiosResponse) => {
      const { config, status, statusText } = response;
      const { method, url, headers } = config;

      if (config) {
        appInsights.trackEvent({
          name: 'API Response Subaru CA',
          properties: {
            ApplicationName: "subaru-ca",
            RequestPath: url ?? '',
            Method: method ?? '',
            CorrelationId: (headers ? headers['X-Correlation-Id'] : ""),
            StatusCode: status,
            StatusCodeText: statusText
          }
        });
      }

      return response;
    }, (error: any) => {
      // You can also log the error details in case of request failure
      appInsights.trackEvent({
        name: 'API Error',
        properties: {
          message: error.message,
          // Additional properties as needed
        },
      });

      return Promise.reject(new Error(error));
    })

    return () => {
      axios.interceptors.response.eject(myResponseInterceptor)
    }
  })

  useEffect(() => {
    const pageInfo: IPageViewTelemetry = {
      name: location?.pathname,
      uri: window?.location.href,
      isLoggedIn: !!store.getState().user?.user?.token
    }
    if (env.APPLICATIONINSIGHTS_CONNECTION_STRING) {
      appInsights.trackPageView(pageInfo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <RouterProvider routesConfig={routerConfig}>
      <App />
    </RouterProvider>
  )
}

export default AppWrapper